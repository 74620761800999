.DashBody {
  align-items: center;
  background-color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 80vh;
}

.DashBody-approve,
.DashBody-cancel,
.DashBody-enrol,
.DashBody-text {
    font-size: 18vh; /* golden ration compared to header */
}

.DashBody-approve {
  background-color: #1c5f05;
  color: #ddd;
}

.DashBody-cancel {
  background-color: #8d210d;
  color: #ddd;
}

.DashBody-enrol {
  background-color: #1658bc;
  color: #ddd;
}

.DashBody-text {
  background-color: #ddd;
  color: #444;
}

.DashBody-smallText {
    font-size: 12vh;
}