/* Class body Element CSS values for Class Viewer App */
.ClassBodyElement-icon {
  padding-right: 0.125em;
}

.ClassBodyElement-icon img {
  width: 1em;
}

.ClassBodyElement-text {
    flex-grow: 1;
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.ClassBodyElement-subtext {
  font-style: italic;
  font-size: .85rem;
  color: #757575;
  display: block;
}

.approved {
  color: #01670f;
}

.cancelled {
  color: #a52934;
}

.enrolling {
  color: #1658bc;
}

.error {
  color: #a52d00;
}

.notification {
  color: grey;
}

.waitlisting {
  color: #1658bc;
}

.openForApplication {
  color: #1658bc;
}
