.app-wrapper {
  display: flex;
  margin: 0 auto;
  max-width: 768px;
  padding: 0 16px;
}

a:link, a:visited {
  color: #fff;
}

a:hover {
  text-decoration: underline;
}

.header, a:active {
  color: #fff;
}