.DashTile {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.DashTile-icon {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 5vh;
  justify-content: center;
}

  .DashTile-icon img {
    height: 18vh;
    width: 18vh;
  }

.DashTile-text {
  flex-grow: 1;
}
