.EnvironmentBar {
  background-image: linear-gradient(rgba(175,39,47,1),rgba(175,39,47,1), rgba(175,39,47,0));
  color: #fff;
  font-size: 2vh;
  opacity: 0.75;
  padding: 0.42vw 0 1vw 0;
  position: fixed;
  text-align: center;
  width: 100%;
}

.EnvironmentBar-local {
  background-image: linear-gradient(rgba(178,0,255,1),rgba(178,0,255,1), rgba(178,0,255,0));
  color: #ffffe0;
}

.EnvironmentBar-stage {
  background-image: linear-gradient(rgba(175,39,47,1),rgba(175,39,47,1), rgba(175,39,47,0));
  color: #ffffe0;
}
