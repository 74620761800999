.DashHeader {
  align-items: center;
  background-color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 20vh;
}

.DashHeader-wrapper {
  align-content: center;
  display: flex;
  flex-direction: row;
}

.DashHeader-uniqueRef {
  color: #ddd;
  font-size: 11vh;
  font-weight: 700;
  padding: 2vh 0 0 0;
}
